import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ProductItem from "./ProductItem";
import { Context } from "../AppContext";

export default function ProductGrid({ products }) {
  const app = useContext(Context);
  const [specs, setSpecs] = useState({});

  useEffect(() => {
    if (!products?.length || !app.state.spec) return;

    async function loadSpecs() {
      const specsMap = {};

      for (const product of products) {
        if (product?.name) {
          try {
            const spec = await app.actions.getProductFromSpec({
              productName: product.name,
              spec: app.state.spec,
            });
            specsMap[product.name] = spec;
          } catch (error) {
            console.error(`Failed to load spec for ${product.name}`);
          }
        }
      }

      setSpecs(specsMap);
    }

    loadSpecs();
  }, [products, app.state.spec]);

  useEffect(() => {
    return () => {
      if (app.state.loading) {
        app.actions.setLoading(false);
      }
    };
  }, []);

  const handleClick = () => {
    if (app.state.loading) return;
    app.actions.setLoading(true);
  };

  return (
    <Wrapper>
      {products.map((p, idx) => (
        <ProductItem
          key={idx}
          product={p}
          spec={specs[p.name]}
          onClick={handleClick}
          link={`/product/${p.id}`}
        />
      ))}
      {[0, 1, 2].map(e => (
        <ProductItem key={e} product={null} />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
`;
